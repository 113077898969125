@import './themes/default.css';
@import './themes/theme1.css';
@import './themes/theme2.css';
@import './themes/theme3.css';

@import './powerreview/style.css';

html {
  padding: 0;
}

body {
  color: #2d3748;
}

/* This style corresponds to
 * the footer component due to
 * a bug in TailwindCSS not
 * recognizing template columns
 * with more than 4 columns.
 * see components/commercetools-ui/footer/index.tsx
 * This is a hardcoded and messy
 * workaround.
 */
@media (min-width: 768px) {
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

form input[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23CE3E72' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>");
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
form input[type='checkbox']:checked:hover {
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.select-accent {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggc3Ryb2tlPScjY2UzZTcyJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMS41JyBkPSdNNiA4bDQgNCA0LTQnLz48L3N2Zz4K');
}

.chakra-accordion__item:last-of-type {
  border-bottom: none;
}

.ie-div-position-customer-chat {
  display: none !important;
}

.chakra-alert div {
  padding-right: 12px;
}

#bluecoreActionScreen {
  z-index: 1 !important;
}
